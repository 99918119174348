import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        super.connect();

        let elements = this.element.querySelectorAll('[data-fieldset]');
        let fieldsetNames = {};
        elements.forEach(function(element) {
            fieldsetNames[ element.getAttribute('data-fieldset') ] = 1;
        })

        Object.keys(fieldsetNames).forEach(function(fieldsetName) {
            let fieldsetElements = this.element.querySelectorAll('[data-fieldset="' + fieldsetName + '"]');

            let fieldset = document.createElement('fieldset');

            let legend = document.createElement('legend');
            legend.innerText = fieldsetName;
            fieldset.append(legend);

            fieldsetElements[ 0 ].closest('.row').before(fieldset);

            fieldsetElements.forEach(function(element) {
                fieldset.append(element.closest('.row'));
            });

            let helpText = fieldsetElements[ 0 ].getAttribute('data-fieldset-help');
            if (helpText != null) {
                let help = document.createElement('div');
                help.classList.add('text-muted', 'col-lg-8');
                help.innerHTML = helpText.split("\n").join('<br>');

                let helpRow = document.createElement('div');
                helpRow.classList.add('row', 'mb-6', 'justify-content-md-end');
                helpRow.append(help);

                fieldset.append(helpRow);
            }
        }.bind(this));
    }
}