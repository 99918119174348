import { Controller } from '@hotwired/stimulus';

import Routing from "../../../public/bundles/fosjsrouting/js/router";
const routes = require('../../../assets/js/fos_js_routes.json'); //file with generated routes, created after executing the command above.
Routing.setRoutingData(routes);

import Swal from "sweetalert2";
import "select2";

export default class extends Controller {
    static targets = [
        'input',
        'values'
    ];

    static values = {
        name: String,
        multiple: Boolean,
    }

    async uploadFile() {
        this.inputTarget.closest('form').querySelector('[type="submit"]').classList.add('disabled');

        for (let i = 0; i < this.inputTarget.files.length; i++) {
            let formData = new FormData();
            formData.append('file', this.inputTarget.files[ i ]);

            let response = await fetch(Routing.generate('admin_upload_file'), {
                method: "POST",
                body: formData
            });

            let result = await response.json();

            let newItem = document.createElement('div');
            newItem.innerHTML = '<div class="item" data-uuid="' + result.uuid + '">\n' +
                '<a href="#" class="btn btn-danger btn-icon" data-action="field--file#deleteFile">\n' +
                    '<i class="fa fa-times"></i>\n' +
                '</a>\n' +
                '<div class="image" style="background-image: url(\'' + Routing.generate('admin_file_download', {uuid: result.uuid, thumbnailSize: 80}) + '\')"></div>\n' +
                '<a href="' + Routing.generate('admin_file_download', {uuid: result.uuid}) + '" class="name">' + this.inputTarget.files[ i ].name + '</a>\n' +
                '<input type="hidden" name="' + this.nameValue + (this.multipleValue ? '[]' : '') + '" value="' + result.uuid + '">\n' +
            '</div>';

            if (!this.multipleValue) {
                this.valuesTarget.innerHTML = '';
            }

            this.valuesTarget.append(newItem);
        }

        this.inputTarget.value = "";

        this.inputTarget.closest('form').querySelector('[type="submit"]').classList.remove('disabled');
    }

    deleteFile(event) {
        event.preventDefault();

        event.target.closest('.item').remove();
    }
}