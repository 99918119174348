import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    getRequestParams() {
        if (window.location.search == '') {
            return {};
        }

        var params = {};
        window.location.search.replace(new RegExp('^\\?'), '').split('&').forEach(function(value) {
            value = value.split('=');
            params[ value[ 0 ] ] = value[ 1 ];
        });

        return params;
    };

    setRequestParams(params) {
        var paramsArray = [];

        var keys = Object.keys(params);
        for (var i in keys)
            if (keys[ i ] != '')
                paramsArray.push(keys[ i ] + '=' + params[ keys[ i ] ]);

        if (window.disablePushState == undefined || !window.disablePushState) {
            history.pushState(params, document.querySelector('title').innerText.trim(), window.location.pathname + (paramsArray.length > 0 ? '?' + paramsArray.join('&') : ''));
        }
    };

    initialize() {
        this.element.querySelectorAll('.nav .nav-link').forEach(function(element) {
            element.addEventListener('click', function(event) {
                let tabName = event.target.getAttribute('href').replace(new RegExp('^#'), '');
                let isFirst = Array.prototype.slice.call(event.target.closest('.nav').querySelectorAll('.nav-link')).indexOf(event.target) === 0;

                var params = this.getRequestParams();

                if (isFirst)
                    delete params[ 'tab' ];
                else
                    params[ 'tab' ] = tabName;

                this.setRequestParams(params);
            }.bind(this));
        }.bind(this));

        window.onpopstate = (event) => {
            let tab = document.querySelectorAll('.nav .nav-link').item(0);
            if (event.state != undefined && event.state.tab != undefined) {
                let item = document.querySelector('.nav .nav-link[href^="#' + event.state.tab + '"]');

                if (item != null)
                    tab = item;
            }

            if (tab != null) {
                window.disablePushState = true;
                tab.click();
                window.disablePushState = false;
            }
        }
    }
}