import { Controller } from '@hotwired/stimulus';

import Routing from "../../../public/bundles/fosjsrouting/js/router";
const routes = require('../../../assets/js/fos_js_routes.json'); //file with generated routes, created after executing the command above.
Routing.setRoutingData(routes);

import Swal from "sweetalert2";
import "select2";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default class extends Controller {
    static targets = [
        'table',
    ];

    static values = {
        customPropertyValues: Object,
        files: Object
    };

    connect() {
        // инициализация первоначальных значений
        this.loadValues();

        let rows = this.tableTarget.querySelectorAll('tr');
        rows.forEach(function(row, index) {
            this.showValueInput(row);
        }.bind(this));

        this.tableTarget.querySelectorAll('select[name$="[type]"]').forEach(function(element) {
            element.onchange = this.updateValueField.bind(this);
        }.bind(this));
    }

    showValueInput(row) {
        if (row.querySelector('[name$="[code]"]') == null) {
            return;
        }

        let code = row.querySelector('[name$="[code]"]').value;
        let type = parseInt(row.querySelector('[name$="[type]"]').value);
        let isMultiple = row.querySelector('[name$="[multiple]"]').checked;
        let fieldHtml = '';

        row.querySelector('.value-column').innerHTML = '';

        if (!isNaN(type)) {
            switch (type) {
                case 1:
                    fieldHtml = '<input type="text" id="properties_value_' + code + (isMultiple ? '___index__' : '') + '" data-action="group--properties#setValueAttribute" ' +
                        'name="properties[value_' + code + ']' + (isMultiple ? '[__index__]' : '') + '" class="form-control-lg form-control-solid form-control" ' +
                        'value="" autocomplete="off">';
                    break;
                case 2:
                    fieldHtml = '<textarea id="properties_value_' + code + (isMultiple ? '___index__' : '') + '" data-action="group--properties#setValueAttribute" ' +
                        'name="properties[value_' + code + ']' + (isMultiple ? '[__index__]' : '') + '" class="form-control-lg form-control-solid form-control" autocomplete="off"></textarea>';
                    break;
                case 3:
                    fieldHtml = '<input type="checkbox" id="properties_value_' + code + (isMultiple ? '___index__' : '') + '" data-action="group--properties#setValueAttribute" ' +
                        'name="properties[value_' + code + ']' + (isMultiple ? '[__index__]' : '') + '" class="mt-3 form-check-input" ' +
                        'value="1" autocomplete="off">';
                    break;
                case 4:
                    fieldHtml = '<input type="number" id="properties_value_' + code + (isMultiple ? '___index__' : '') + '" data-action="group--properties#setValueAttribute" ' +
                        'name="properties[value_' + code + ']' + (isMultiple ? '[__index__]' : '') + '" class="form-control-lg form-control-solid form-control" ' +
                        'value="" autocomplete="off">';
                    break;
                case 5:
                    fieldHtml = '<div class="file-form" data-controller="field--file" data-field--file-name-value="properties[value_' + code + ']" data-field--file-multiple-value="0">' +
                        '<div class="value" data-field--file-target="values">' +
                        (this.customPropertyValuesValue[ code ] != null && (new RegExp('^[a-f0-9\\-]{36}$', 'i')).test(this.customPropertyValuesValue[ code ]) ? '<div class="item" data-uuid="' + this.customPropertyValuesValue[ code ] + '">' +
                            '<a href="#" class="btn btn-danger btn-icon" data-action="field--file#deleteFile">' +
                                '<i class="fa fa-times"></i>' +
                            '</a>' +
                            '<div class="image" style="background-image: url(\'' + Routing.generate('admin_file_download', {uuid: this.customPropertyValuesValue[ code ], thumbnail: 80}) + '\')"></div>' +
                            '<span class="name">' + (this.filesValue[ this.customPropertyValuesValue[ code ] ] ?? 'Файл') + '</span>' +
                            '<input type="hidden" id="properties_value_' + code + (isMultiple ? '___index__' : '') + '" name="properties[value_' + code + ']" value="">' +
                            '</div>' : '') +
                        '</div>' +
                        '<input type="file" ' + (isMultiple ? 'multiple ' : '') + 'class="form-control-lg form-control-solid form-control" data-action="field--file#uploadFile" data-field--file-target="input">' +
                    '</div>';
                    break;
                case 6:
                    fieldHtml = '<textarea id="properties_value_' + code + (isMultiple ? '___index__' : '') + '" data-action="group--properties#setValueAttribute" ' +
                        'name="properties[value_' + code + ']' + (isMultiple ? '[__index__]' : '') + '" class="form-control-lg form-control-solid form-control editor" autocomplete="off"></textarea>';
                    break;
                default:
                    alert('Неизвестный тип поля.');
            }
        } else {
            return;
        }

        // создаем поля ввода
        if (isMultiple) {
            row.querySelector('.value-column').innerHTML = '<div class="file-form" data-controller="field--input" data-field--input-name-value="properties[value_' + code + ']">' +
                '<div class="value" data-field--input-target="values">' +
                '</div>\n' +
                '<a href="#" class="btn btn-success" data-action="field--input#addItem">\n' +
                '<i class="fa fa-plus"></i> Добавить\n' +
                '</a>\n' +
                '</div>';
            row.querySelector('.value-column .file-form').setAttribute('data-prototype', fieldHtml);

            let valuesContainer = row.querySelector('.value-column .value');
            for (var i in this.customPropertyValuesValue[ code ]) {
                let newItem = document.createElement('div');
                newItem.classList.add('item');
                newItem.innerHTML = '<a href="#" class="btn btn-danger btn-icon" data-action="group--properties#deleteItem field--input#deleteItem">\n' +
                    '<i class="fa fa-times"></i>\n' +
                    '</a>' +
                    fieldHtml.replaceAll('__index__', i);

                if (newItem.querySelector('input[name], select[name], textarea[name]') != null) {
                    newItem.querySelector('input[name], select[name], textarea[name]').value = this.customPropertyValuesValue[ code ][ i ];
                }

                valuesContainer.append(newItem);
            }

            if (type === 5) {
                row.querySelectorAll('.value-column .value .item').forEach(function(element) {
                    element.remove();
                });
                row.querySelector('.value-column .btn').remove();

                row.querySelector('.value-column .file-form').innerHTML += '<input type="file" multiple class="form-control-lg form-control-solid form-control" data-action="field--file#uploadFile" data-field--file-target="input">';
                row.querySelector('.value-column .file-form').setAttribute('data-field--file-multiple-value', '1');
                row.querySelector('.value-column .file-form').setAttribute('data-field--file-name-value', 'properties[value_' + code + ']');
                row.querySelector('.value-column .file-form .value').setAttribute('data-field--file-target', 'values');
                row.querySelector('.value-column .file-form').setAttribute('data-controller', 'field--file');

                let valuesContainer = row.querySelector('.value-column .value');
                for (var i in this.customPropertyValuesValue[ code ]) {
                    let newItem = document.createElement('div');
                    newItem.innerHTML = '<div class="item" data-uuid="' + this.customPropertyValuesValue[ code ][ i ] + '">\n' +
                        '<a href="#" class="btn btn-danger btn-icon" data-action="field--file#deleteFile">\n' +
                            '<i class="fa fa-times"></i>\n' +
                        '</a>\n' +
                        '<div class="image" style="background-image: url(\'' + Routing.generate('admin_file_download', {uuid: this.customPropertyValuesValue[ code ][ i ], thumbnail: 80}) + '\')"></div>\n' +
                        '<span class="name">' + (this.filesValue[ this.customPropertyValuesValue[ code ][ i ] ] ?? 'Файл') + '</span>\n' +
                        '<input type="hidden" name="properties[value_' + code + '][]" value="' + this.customPropertyValuesValue[ code ][ i ] + '">\n' +
                    '</div>';

                    valuesContainer.append(newItem);
                }
            }
        } else {
            row.removeAttribute('data-prototype');
            row.querySelector('.value-column').innerHTML = fieldHtml;

            if (row.querySelector('.value-column').querySelector('input[name], select[name], textarea[name]') != null) {
                row.querySelector('.value-column').querySelector('input[name], select[name], textarea[name]').value =
                    this.customPropertyValuesValue[ code ] != null && this.customPropertyValuesValue[ code ] != undefined ?
                        this.customPropertyValuesValue[ code ][ 0 ] :
                        null;
            }
        }

        if (type == 6) {
            row.querySelector('.value-column').querySelectorAll('textarea[name]').forEach(function(element) {
                ClassicEditor
                    .create( document.querySelector('#' + element.id) )
                    .then( editor => {
                        window.editor = editor;
                    } )
                    .catch( error => {
                        console.error( 'There was a problem initializing the editor.', error );
                    } );
            });
        }
    }

    enumRows() {
        let rows = this.tableTarget.querySelectorAll('tr');

        rows.forEach(function(row, index) {
            let elements = row.querySelectorAll('input[name], select[name], textarea[name]');
            elements.forEach(function(element) {
                if (element.getAttribute('name') != null) {
                    element.setAttribute('name', element.getAttribute('name').replace('__name__', '0').replace(new RegExp('\\[(\\d+?)\\]\\[([^\\]]+?)\\]$'), '[' + index + '][$2]'));
                }

                if (element.getAttribute('id') != null) {
                    element.setAttribute('id', element.getAttribute('id').replace('__name__', '0').replace(new RegExp('(\\d+?)_([^_]+?)$'), '' + index + '_$2'));
                }
            });
        });
    }

    addProperty(event) {
        event.preventDefault();

        if (this.tableTarget.querySelector('tr .empty') != null) {
            this.tableTarget.querySelector('tr').remove();
        }

        var newEntry = document.createElement('tr');
        newEntry.innerHTML = this.element.getAttribute('data-prototype');
        this.tableTarget.append(newEntry);

        $(newEntry.querySelector('select[name$="[type]"]')).select2();
        newEntry.querySelector('[name$="[type]"]').onchange = this.updateValueField.bind(this);
        newEntry.querySelector('[name$="[sort]"]').value = 100;

        this.enumRows();
    }

    deleteProperty(event) {
        event.preventDefault();

        event.target.closest('tr').remove();
        this.enumRows();

        if (this.tableTarget.querySelectorAll('tr').length == 0) {
            let emptyElement = document.createElement('tr');
            emptyElement.innerHTML = '<tr><td colspan="7" class="empty">Дополнительные параметры не указаны.</td></tr>'

            this.tableTarget.append(emptyElement);
        }
    }

    loadValues() {
        // сохранение значений свойств в атрибуты рядов таблицы
        let rows = this.tableTarget.querySelectorAll('tr');

        var files = this.filesValue;

        var values = {};
        rows.forEach(function(row, index) {
            let code = row.querySelector('[name$="[code]"]').value;
            let inputs = document.querySelectorAll('[name*="[value_' + code + ']"');

            if (inputs.length === 0) {
                inputs = row.querySelector('.value-column').querySelectorAll('input[name], select[name], textarea[name]');
            }

            inputs.forEach(function(input) {
                if (values[ code ] == undefined) {
                    values[ code ] = [];
                }

                values[ code ].push(input.type == 'checkbox' ? (input.checked ? '1' : '') : input.value);

                if (input.closest('.hidden-properties') != null) {
                    input.remove();
                }
            });

            // имена файлов
            row.querySelector('.value-column').querySelectorAll('.item[data-uuid]').forEach(function(element) {
                let uuid = element.getAttribute('data-uuid');
                let name = element.querySelector('.name').innerText;

                if (files[ uuid ] == undefined) {
                    files[ uuid ] = name;
                }
            });
        }.bind(this));

        this.filesValue = files;
        this.customPropertyValuesValue = values;
    }

    updateValueField(event) {
        // изменение поля Код, Тип, Множественное
        this.loadValues();
        this.showValueInput(event.target.closest('tr'));
    }

    setValueAttribute(event) {
        // изменение поля со значением
        this.loadValues();
    }

    deleteItem(event) {
        setTimeout(function() {
            this.loadValues();
        }.bind(this), 10);
    }
}