import { Controller } from '@hotwired/stimulus';

import Routing from "../../../public/bundles/fosjsrouting/js/router";
const routes = require('../../../assets/js/fos_js_routes.json'); //file with generated routes, created after executing the command above.
Routing.setRoutingData(routes);

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default class extends Controller {
    connect() {
        ClassicEditor
            .create( document.querySelector( '#' + this.element.id ) )
            .then( editor => {
                window.editor = editor;
            } )
            .catch( error => {
                console.error( 'There was a problem initializing the editor.', error );
            } );
    }
}