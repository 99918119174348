import { Controller } from '@hotwired/stimulus';

import Routing from "../../../public/bundles/fosjsrouting/js/router";
const routes = require('../../../assets/js/fos_js_routes.json'); //file with generated routes, created after executing the command above.
Routing.setRoutingData(routes);

import Swal from "sweetalert2";

export default class extends Controller {
    static targets = [
        'table',
        'tableSummary',
        'price',
    ];

    static values = {
        group: String,
        requestType: Number,
    };

    connect() {
        this.display();
    }

    getBasketId() {
        return 'basket_' + this.groupValue;
    }

    async display() {
        let basket = localStorage.getItem(this.getBasketId()) != null ?
            JSON.parse(localStorage.getItem(this.getBasketId())) : [];

        let response = await $.post(Routing.generate('public_block_basket', {
            code: this.groupValue,
        }), {
            data: basket,
            request_type_id: this.requestTypeValue,
        });

        let element = document.createElement('div');
        element.innerHTML = response;

        this.tableTarget.innerHTML = element.querySelector('[data-block--basket-target="table"]').innerHTML;
        this.priceTarget.innerHTML = element.querySelector('[data-block--basket-target="table-summary"]').innerHTML;
    }

    order(e) {
        e.preventDefault();

        let rows = this.tableTarget.querySelectorAll('tbody tr');

        console.log(rows);

        if (rows.length == 0) {
            return;
        }

        let modal = document.querySelector(this.element.querySelector('[data-basket-request-button] button').getAttribute('data-bs-target'));
        let input = modal.querySelector('[name$="[additionalContent]"]');

        let content = [];
        rows.forEach(function(element) {
            let name = element.querySelector('[data-attribute="name"]').innerText;
            let count = element.querySelector('[data-attribute="count"]').innerText;
            let price = element.querySelector('[data-attribute="price"]').innerText;

            content.push(name + ' (' + price + ' руб.) - ' + count + ' шт.');
        })

        content.push('Итого: ' + this.element.querySelector('[data-block--basket-target="table-summary"]').innerText + 'руб.');

        input.value = content.join("\n");

        this.element.querySelector('[data-basket-request-button] button').click();
    }
}