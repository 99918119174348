import { Controller } from '@hotwired/stimulus';
import 'ace-builds';
import 'ace-builds';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/ext-language_tools';
//import 'emmet';
//import 'ace-builds/src-noconflict/ext-emmet';

ace.require("ace/ext/language_tools");
//ace.require("ace/ext/emmet");

export default class extends Controller {
    static targets = [
        'data'
    ];

    static values = {
        extension: String
    };

    connect() {
        let modes = {
            'js': 'ace/mode/javascript',
            'css': 'ace/mode/css',
            'twig': 'ace/mode/twig',
        };

        this.dataTarget.closest('div').innerHTML += '<div id="editor">' + '' + '</div>';
        this.dataTarget.setAttribute('hidden', true);

        let setEditorWidth = function() {
            let header = document.querySelector('#kt_header');
            let headerRect = header.getBoundingClientRect();

            let container = document.querySelector('#kt_content');
            let containerRect = container.getBoundingClientRect();

            let form = container.querySelector('form');
            let formRect = form.getBoundingClientRect();

            let element = document.querySelector('#editor');
            let elementRect = element.getBoundingClientRect();

            let height = containerRect.height - formRect.height + elementRect.height - headerRect.height - 65;
            element.style = 'height: ' + Math.max(height, 300) + 'px';
        };

        setEditorWidth();
        window.onresize = setEditorWidth;

        let editor = ace.edit("editor");
        editor.setTheme("ace/theme/textmate");
        editor.setOptions({
            //enableEmmet: true,
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
        });
        editor.setValue(this.dataTarget.value);

        if (modes[ this.extensionValue ] != undefined) {
            editor.session.setMode(modes[ this.extensionValue ]);
        }

        editor.session.on('change', function(delta) {
            this.dataTarget.value = editor.getValue();
        }.bind(this));
    }
}
