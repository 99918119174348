import {Controller} from '@hotwired/stimulus';

import Routing from "../../../public/bundles/fosjsrouting/js/router";
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import SimpleImage from '@editorjs/simple-image';
import Quote from '@editorjs/quote';
import Warning from '@editorjs/warning';
import Marker from '@editorjs/marker';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';
import ImageTool from '@editorjs/image';
import LinkTool from '@editorjs/link';
import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import RawTool from '@editorjs/raw';
import Paragraph from '@editorjs/paragraph';
import Alert from 'editorjs-alert';
import AttachesTool from '@editorjs/attaches';
import ColorPlugin from 'editorjs-text-color-plugin';
import ImageGallery from '@kiberpro/editorjs-gallery';
import Sortable from 'sortablejs';
import HTMLJanitor from 'html-janitor';

const routes = require('../../../assets/js/fos_js_routes.json'); //file with generated routes, created after executing the command above.
Routing.setRoutingData(routes);

class BlockPlugin {
    static allBlocks = null;
    static allTemplates = null;

    constructor({data}) {
        this.data = data;

        this.fetchParams().then(function () {
            if (this.block != null) {
                this.setBlocksList(this.block.querySelector('select[name="block_id"]'));
                this.setSectionsList(this.block.querySelector('select[name="section_id"]'));
                this.setElementsList(this.block.querySelector('select[name="element_id"]'));
                this.setTemplatesList(this.block.querySelector('select[name="template"]'));

                this.block.querySelector('.card-body').removeAttribute('hidden');
                this.block.querySelector('.card-header .spinner-border').setAttribute('hidden', true);
            }
        }.bind(this));
    }

    async fetchParams() {
        if (self.allBlocks != null) {
            return;
        }

        let groupId = window.location.pathname.split('/')[ 3 ];
        let response = await fetch(Routing.generate('admin_blocks_index_json', {
            id: groupId
        }));
        let data = await response.json();

        self.allBlocks = data.data;
        self.allTemplates = data.templates;
    }

    static get toolbox() {
        return {
            title: 'Блок',
            icon: '<i class="fa fa-cogs text-black"></i>'
        };
    }

    formatRow(element, label) {
        let parent = document.createElement('div');
        parent.classList.add('row', 'mb-3');

        let labelElement = document.createElement('label');
        labelElement.classList.add('col-lg-2', 'col-form-label');
        labelElement.innerText = label;

        let elementContainer = document.createElement('div');
        elementContainer.classList.add('col-lg-10');
        elementContainer.append(element);

        parent.append(labelElement, elementContainer);

        return parent;
    }

    setBlocksList(blockInput) {
        if (blockInput == null) {
            return;
        }

        for (let i in self.allBlocks) {
            let option = document.createElement('option');
            option.value = self.allBlocks[ i ].id;
            option.innerText = self.allBlocks[ i ].name;

            if (blockInput.querySelector('option[value="' + option.value + '"]') != null) {
                continue;
            }

            blockInput.append(option);
        }

        if (blockInput.querySelector('option[value="' + this.data.block_id + '"]') != null) {
            blockInput.value = this.data.block_id;
        }

        if (blockInput.querySelector('option[value="' + blockInput.getAttribute('data-value') + '"]') != null) {
            blockInput.value = blockInput.getAttribute('data-value');
        }
    }

    setSectionsList(sectionInput) {
        if (sectionInput == null) {
            return;
        }

        if (this.block == null) {
            return;
        }

        sectionInput.value = '';
        sectionInput.querySelectorAll('option').forEach(function(element) {
            if (element.getAttribute('value') == '') {
                return;
            }

            element.remove();
        });

        let blockId = this.block.querySelector('select[name="block_id"]').value;
        if (blockId == '') {
            return;
        }

        if (blockId != '') {
            let currentBlock = null;
            for (let i in self.allBlocks) {
                if (self.allBlocks[ i ].id == blockId) {
                    currentBlock = self.allBlocks[ i ];
                    break;
                }
            }

            if (currentBlock != null) {
                for (let i in currentBlock.sections) {
                    let option = document.createElement('option');
                    option.value = currentBlock.sections[ i ].id;
                    option.innerText = currentBlock.sections[ i ].path;
                    sectionInput.append(option);
                }
            }
        }

        if (sectionInput.querySelector('option[value="' + this.data.section_id + '"]') != null) {
            sectionInput.value = this.data.section_id;
        }
    }

    setElementsList(elementInput) {
        if (elementInput == null) {
            return;
        }

        if (this.block == null) {
            return;
        }

        elementInput.value = '';
        elementInput.querySelectorAll('option').forEach(function(element) {
            if (element.getAttribute('value') == '') {
                return;
            }

            element.remove();
        });

        let blockId = this.block.querySelector('select[name="block_id"]').value;
        if (blockId == '') {
            return;
        }

        if (blockId != '') {
            let currentBlock = null;
            for (let i in self.allBlocks) {
                if (self.allBlocks[ i ].id == blockId) {
                    currentBlock = self.allBlocks[ i ];
                    break;
                }
            }

            if (currentBlock != null) {
                for (let i in currentBlock.elements) {
                    let option = document.createElement('option');
                    option.value = currentBlock.elements[ i ].id;
                    option.innerText = currentBlock.elements[ i ].path;
                    elementInput.append(option);
                }
            }
        }

        if (elementInput.querySelector('option[value="' + this.data.element_id + '"]') != null) {
            elementInput.value = this.data.element_id;
        }
    }

    setTemplatesList(templateInput) {
        if (templateInput == null) {
            return;
        }

        if (this.block == null) {
            return;
        }

        let keys = Object.keys(self.allTemplates);
        for (let i in keys) {
            let option = document.createElement('option');
            option.value = keys[ i ];
            option.innerText = self.allTemplates[ keys[ i ] ];
            templateInput.append(option);
        }

        if (templateInput.querySelector('option[value="' + this.data.template + '"]') != null) {
            templateInput.value = this.data.template;
        }
    }

    render(){
        let parent = document.createElement('div');
        parent.classList.add('card', 'shadow-sm');

        let parentHeader = document.createElement('div');
        parentHeader.classList.add('card-header');
        parentHeader.innerHTML = '<div class="card-title m-0">\n' +
            '<h3 class="fw-bolder m-0">Блок</h3>\n' +
            '<div class="spinner-border text-muted ms-3" hidden></div>\n' +
        '</div>';
        parent.append(parentHeader);

        let parentBody = document.createElement('div');
        parentBody.classList.add('card-body');
        parent.append(parentBody);

        if (this.allBlock == null) {
            parentBody.setAttribute('hidden', true);
            parentHeader.querySelector('.spinner-border').removeAttribute('hidden');
        }

        // блок
        let blockInput = document.createElement('select');
        blockInput.name = 'block_id';
        blockInput.classList.add('form-control');

        let blockInputOption = document.createElement('option');
        blockInputOption.value = '';
        blockInputOption.innerText = '[Не выбран]';
        blockInput.append(blockInputOption);

        blockInput.setAttribute('data-value', this.data.block_id);
        this.setBlocksList(blockInput);

        blockInput.addEventListener('change', function(e) {
            this.setSectionsList(this.block.querySelector('select[name="section_id"]'));
            this.setElementsList(this.block.querySelector('select[name="element_id"]'));
        }.bind(this));

        parentBody.append(this.formatRow(blockInput, 'Блок'));

        // раздел
        let sectionInput = document.createElement('select');
        sectionInput.name = 'section_id';
        sectionInput.classList.add('form-control');

        let sectionInputOption = document.createElement('option');
        sectionInputOption.value = '';
        sectionInputOption.innerText = '[Корневой раздел]';
        sectionInput.append(sectionInputOption);

        sectionInput.setAttribute('data-value', this.data.section_id);
        this.setSectionsList(sectionInput);

        parentBody.append(this.formatRow(sectionInput, 'Раздел'));

        // элемент
        let elementInput = document.createElement('select');
        elementInput.name = 'element_id';
        elementInput.classList.add('form-control');

        let elementInputOption = document.createElement('option');
        elementInputOption.value = '';
        elementInputOption.innerText = '[Не выбран]';
        elementInput.append(elementInputOption);

        elementInput.setAttribute('data-value', this.data.element_id);
        this.setElementsList(elementInput);

        parentBody.append(this.formatRow(elementInput, 'Элемент'));

        // шаблон
        let templateInput = document.createElement('select');
        templateInput.name = 'template';
        templateInput.classList.add('form-control');

        let templateInputOption = document.createElement('option');
        templateInputOption.value = '';
        templateInputOption.innerText = '[Шаблон для блока по умолчанию]';
        templateInput.append(templateInputOption);

        templateInput.setAttribute('data-value', this.data.template);
        this.setTemplatesList(templateInput);

        parentBody.append(this.formatRow(templateInput, 'Шаблон'));

        this.block = parent;

        return parent;
    }

    save(blockContent){
        return {
            block_id: blockContent.querySelector('select[name="block_id"]').value,
            section_id: blockContent.querySelector('select[name="section_id"]').value,
            element_id: blockContent.querySelector('select[name="element_id"]').value,
            template: blockContent.querySelector('select[name="template"]').value,
        }
    }
}

class RequestPlugin {
    static allTypes = null;
    static allTemplates = null;

    constructor({data}) {
        this.data = data;

        this.fetchParams().then(function () {
            if (this.requestType != null) {
                this.setTypesList(this.requestType.querySelector('select[name="request_type_id"]'));
                this.setTemplatesList(this.requestType.querySelector('select[name="template"]'));

                this.requestType.querySelector('.card-body').removeAttribute('hidden');
                this.requestType.querySelector('.card-header .spinner-border').setAttribute('hidden', true);
            }
        }.bind(this));
    }

    async fetchParams() {
        if (self.allTypes != null) {
            return;
        }

        let groupId = window.location.pathname.split('/')[ 3 ];
        let response = await fetch(Routing.generate('admin_request_types_index_json', {
            id: groupId
        }));
        let data = await response.json();

        self.allTypes = data.data;
        self.allTemplates = data.templates;
    }

    static get toolbox() {
        return {
            title: 'Заявка',
            icon: '<i class="fa fa-id-card text-black"></i>'
        };
    }

    formatRow(element, label) {
        let parent = document.createElement('div');
        parent.classList.add('row', 'mb-3');

        let labelElement = document.createElement('label');
        labelElement.classList.add('col-lg-2', 'col-form-label');
        labelElement.innerText = label;

        let elementContainer = document.createElement('div');
        elementContainer.classList.add('col-lg-10');
        elementContainer.append(element);

        parent.append(labelElement, elementContainer);

        return parent;
    }

    setTypesList(typeInput) {
        if (typeInput == null) {
            return;
        }

        for (let i in self.allTypes) {
            let option = document.createElement('option');
            option.value = self.allTypes[ i ].id;
            option.innerText = self.allTypes[ i ].name;

            if (typeInput.querySelector('option[value="' + option.value + '"]') != null) {
                continue;
            }

            typeInput.append(option);
        }

        if (typeInput.querySelector('option[value="' + this.data.request_type_id + '"]') != null) {
            typeInput.value = this.data.request_type_id;
        }

        if (typeInput.querySelector('option[value="' + typeInput.getAttribute('data-value') + '"]') != null) {
            typeInput.value = typeInput.getAttribute('data-value');
        }
    }

    setTemplatesList(templateInput) {
        if (templateInput == null) {
            return;
        }

        if (self.allTemplates == null) {
            return;
        }

        let keys = Object.keys(self.allTemplates);
        for (let i in keys) {
            let option = document.createElement('option');
            option.value = keys[ i ];
            option.innerText = self.allTemplates[ keys[ i ] ];
            templateInput.append(option);
        }

        if (templateInput.querySelector('option[value="' + this.data.template + '"]') != null) {
            templateInput.value = this.data.template;
        }
    }

    render(){
        let parent = document.createElement('div');
        parent.classList.add('card', 'shadow-sm');

        let parentHeader = document.createElement('div');
        parentHeader.classList.add('card-header');
        parentHeader.innerHTML = '<div class="card-title m-0">\n' +
            '<h3 class="fw-bolder m-0">Заявки</h3>\n' +
            '<div class="spinner-border text-muted ms-3" hidden></div>\n' +
            '</div>';
        parent.append(parentHeader);

        let parentBody = document.createElement('div');
        parentBody.classList.add('card-body');
        parent.append(parentBody);

        if (this.allTypes == null) {
            parentBody.setAttribute('hidden', true);
            parentHeader.querySelector('.spinner-border').removeAttribute('hidden');
        }

        // тип заявок
        let typeInput = document.createElement('select');
        typeInput.name = 'request_type_id';
        typeInput.classList.add('form-control');

        let typeInputOption = document.createElement('option');
        typeInputOption.value = '';
        typeInputOption.innerText = '[Не выбран]';
        typeInput.append(typeInputOption);

        typeInput.setAttribute('data-value', this.data.request_type_id);
        this.setTypesList(typeInput);

        parentBody.append(this.formatRow(typeInput, 'Тип заявок'));

        // шаблон
        let templateInput = document.createElement('select');
        templateInput.name = 'template';
        templateInput.classList.add('form-control');

        templateInput.setAttribute('data-value', this.data.template ?? 'default');
        this.setTemplatesList(templateInput);

        parentBody.append(this.formatRow(templateInput, 'Шаблон'));

        this.requestType = parent;

        return parent;
    }

    save(blockContent){
        return {
            request_type_id: blockContent.querySelector('select[name="request_type_id"]').value,
            template: blockContent.querySelector('select[name="template"]').value,
        }
    }
}

class BasketPlugin {
    static allTypes = null;

    constructor({data}) {
        this.data = data;

        this.fetchParams().then(function () {
            if (this.requestType != null) {
                this.setTypesList(this.requestType.querySelector('select[name="request_type_id"]'));

                this.requestType.querySelector('.card-body').removeAttribute('hidden');
                this.requestType.querySelector('.card-header .spinner-border').setAttribute('hidden', true);
            }
        }.bind(this));
    }

    async fetchParams() {
        if (self.allTypes != null) {
            return;
        }

        let groupId = window.location.pathname.split('/')[ 3 ];
        let response = await fetch(Routing.generate('admin_request_types_index_json', {
            id: groupId
        }));
        let data = await response.json();

        self.allTypes = data.data;
    }

    static get toolbox() {
        return {
            title: 'Корзина',
            icon: '<i class="fa fa-cart-plus text-black"></i>'
        };
    }

    formatRow(element, label) {
        let parent = document.createElement('div');
        parent.classList.add('row', 'mb-3');

        let labelElement = document.createElement('label');
        labelElement.classList.add('col-lg-2', 'col-form-label');
        labelElement.innerText = label;

        let elementContainer = document.createElement('div');
        elementContainer.classList.add('col-lg-10');
        elementContainer.append(element);

        parent.append(labelElement, elementContainer);

        return parent;
    }

    setTypesList(typeInput) {
        if (typeInput == null) {
            return;
        }

        for (let i in self.allTypes) {
            let option = document.createElement('option');
            option.value = self.allTypes[ i ].id;
            option.innerText = self.allTypes[ i ].name;

            if (typeInput.querySelector('option[value="' + option.value + '"]') != null) {
                continue;
            }

            typeInput.append(option);
        }

        if (typeInput.querySelector('option[value="' + this.data.request_type_id + '"]') != null) {
            typeInput.value = this.data.request_type_id;
        }

        if (typeInput.querySelector('option[value="' + typeInput.getAttribute('data-value') + '"]') != null) {
            typeInput.value = typeInput.getAttribute('data-value');
        }
    }

    render(){
        let parent = document.createElement('div');
        parent.classList.add('card', 'shadow-sm');

        let parentHeader = document.createElement('div');
        parentHeader.classList.add('card-header');
        parentHeader.innerHTML = '<div class="card-title m-0">\n' +
            '<h3 class="fw-bolder m-0">Корзина</h3>\n' +
            '<div class="spinner-border text-muted ms-3" hidden></div>\n' +
            '</div>';
        parent.append(parentHeader);

        let parentBody = document.createElement('div');
        parentBody.classList.add('card-body');
        parent.append(parentBody);

        if (this.allTypes == null) {
            parentBody.setAttribute('hidden', true);
            parentHeader.querySelector('.spinner-border').removeAttribute('hidden');
        }

        // тип заявок
        let typeInput = document.createElement('select');
        typeInput.name = 'request_type_id';
        typeInput.classList.add('form-control');

        let typeInputOption = document.createElement('option');
        typeInputOption.value = '';
        typeInputOption.innerText = '[Не выбран]';
        typeInput.append(typeInputOption);

        typeInput.setAttribute('data-value', this.data.request_type_id);
        this.setTypesList(typeInput);

        parentBody.append(this.formatRow(typeInput, 'Тип заявок'));

        this.requestType = parent;

        return parent;
    }

    save(blockContent){
        return {
            request_type_id: blockContent.querySelector('select[name="request_type_id"]').value,
        }
    }
}

class CleanParagraph extends Paragraph {
    constructor({ data, config, api, readOnly }) {
        super({ data, config, api, readOnly });

        setTimeout(function() {
            if (this._element == null) {
                return;
            }

            this._element.addEventListener('paste', function(e) {
                setTimeout(function() {
                    this._element.innerHTML = this.clean(this._element.innerHTML);
                    console.log('cleaned in paste handler');
                }.bind(this), 10);
            }.bind(this));
        }.bind(this), 10);
    }

    clean(text) {
        var janitor = new HTMLJanitor({
            tags: {
                p: {},
                a: {
                    href: true
                },
                i: true,
                b: true,
            }
        });
        return janitor.clean(text);
    }

    onPaste(t) {
        const e = {
            text: this.clean(t.detail.data.innerHTML),
        };

        this._data = e, window.requestAnimationFrame(() => {
            this._element && (this._element.innerHTML = this._data.text || "");
            console.log('cleaned in onPaste method');
        });
    }

    onKeyUp(e) {
        let isPasting = (e.ctrlKey && e.key == 'v') ||
            e.code == 'MetaLeft';

        if (isPasting) {
            setTimeout(function() {
                this._element.innerHTML = this.clean(this._element.innerHTML);
                console.log('cleaned in onKeyUp method');
            }.bind(this), 10);
        }
    }
}

export default class extends Controller {
    connect() {
        let element = document.createElement('div');
        element.id = this.element.id + '_editorjs';
        element.classList.add('block-editor');
        this.element.after(element);

        const fileUploader = {
            /**
             * Upload file to the server and return an uploaded image data
             * @param {File} file - file selected from the device or pasted by drag-n-drop
             * @return {Promise.<{success, file: {url}}>}
             */
            async uploadByFile(file) {
                let formData = new FormData();

                formData.append("file", file);
                let response = await fetch(Routing.generate('admin_upload_file'), {
                    method: "POST",
                    body: formData
                });

                let data = await response.json();

                return {
                    success: 1,
                    file: {
                        url: Routing.generate('admin_file_download', {uuid: data.uuid})
                    }
                };
            },

            /**
             * Send URL-string to the server. Backend should load image by this URL and return an uploaded image data
             * @param {string} url - pasted image URL
             * @return {Promise.<{success, file: {url}}>}
             */
            async uploadByUrl(url){
                let formData = new FormData();

                formData.append("url", url);
                let response = await fetch(Routing.generate('admin_upload_url'), {
                    method: "POST",
                    body: formData
                });

                let data = await response.json();

                return {
                    success: 1,
                    file: {
                        url: Routing.generate('admin_file_download', {uuid: data.uuid})
                    }
                };
            }
        };

        let editor = new EditorJS({
            holder: element,
            //inlineToolbar: ['link', 'marker', 'bold', 'italic'],
            inlineToolbar: true,
            sanitizer: {
                font: false,
                b: false,
                a: false,
                div: false,
                p: false,
            },
            tools: {
                header: {
                    class: Header,
                    levels: [1, 2, 3],
                    defaultLevel: 2,
                },
                paragraph: {
                    class: CleanParagraph,
                    inlineToolbar: true,
                },
                simpleImage: SimpleImage,
                image: {
                    class: ImageTool,
                    config: {
                        endpoints: {
                            byFile: Routing.generate('admin_upload_file'),
                            byUrl: Routing.generate('admin_upload_url'),
                        },
                        field: 'file',
                        /**
                         * Custom uploader
                         */
                        uploader: fileUploader,
                    }
                },
                gallery: {
                    class: ImageGallery,
                    config: {
                        sortableJs: Sortable,
                        uploader: fileUploader,
                    },
                },
                attaches: {
                    class: AttachesTool,
                    config: {
                        buttonText: "Выберите файл",
                        uploader: fileUploader,
                    }
                },
                list: {
                    class: List,
                    inlineToolbar: true,
                },
                quote: {
                    class: Quote,
                    inlineToolbar: true,
                    config: {
                        quotePlaceholder: 'Цитата',
                        captionPlaceholder: 'Автор',
                    },
                },
                warning: {
                    class: Warning,
                    inlineToolbar: true,
                    config: {
                        titlePlaceholder: 'Заголовок',
                        messagePlaceholder: 'Текст',
                    },
                },
                alert: {
                    class: Alert,
                    inlineToolbar: true,
                    config: {
                        defaultType: 'primary',
                        messagePlaceholder: 'Текст',
                    },
                },
                marker: Marker,
                delimiter: Delimiter,
                inlineCode: InlineCode,
                linkTool: {
                    class: LinkTool,
                    config: {
                        endpoint: Routing.generate('admin_get_link')
                    }
                },
                embed: {
                    class: Embed,
                    inlineToolbar: true,
                    config: {
                        services: {
                            youtube: true,
                            vimeo: true,
                            coub: true,
                            'yandex-music-track': true,
                            'yandex-music-album': true,
                            'yandex-music-playlist': true,
                        }
                    }
                },
                table: {
                    class: Table,
                    inlineToolbar: true,
                },
                raw: {
                    class: RawTool,
                    config: {
                        placeholder: "Введите HTML-код"
                    }
                },
                color: ColorPlugin,
                block: BlockPlugin,
                request: RequestPlugin,
                basket: BasketPlugin,
            },
            onReady: () => {
                let blocks = this.element.value;
                if (blocks != '') {
                    blocks = JSON.parse(blocks);

                    blocks.forEach(function(block) {
                        editor.blocks.insert(block.type, block.data);
                    })
                }
            },
            i18n: {
                /**
                 * @type {I18nDictionary}
                 */
                messages: {
                    /**
                     * Other below: translation of different UI components of the editor.js core
                     */
                    ui: {
                        "blockTunes": {
                            "toggler": {
                                "Click to tune": "Нажмите, чтобы настроить",
                                "or drag to move": "или перетащите"
                            },
                        },
                        "inlineToolbar": {
                            "converter": {
                                "Convert to": "Конвертировать в"
                            }
                        },
                        "toolbar": {
                            "toolbox": {
                                "Add": "Добавить"
                            },
                        },
                        "popover": {
                            "Filter": "Фильтр",
                            "Nothing found": "Не найдено"
                        },
                    },

                    /**
                     * Section for translation Tool Names: both block and inline tools
                     */
                    toolNames: {
                        "Text": "Параграф",
                        "Heading": "Заголовок",
                        "List": "Список",
                        "Warning": "Примечание",
                        "Checklist": "Чеклист",
                        "Quote": "Цитата",
                        "Code": "Код",
                        "Delimiter": "Разделитель",
                        "Raw HTML": "HTML-фрагмент",
                        "Table": "Таблица",
                        "Link": "Ссылка",
                        "Marker": "Маркер",
                        "Bold": "Полужирный",
                        "Italic": "Курсив",
                        "InlineCode": "Моноширинный",
                        "Image": "Изображение",
                        "Gallery": "Фотогалерея",
                        "Alert": "Предупреждение",
                        "Attachment": "Вложение",
                        "Color": "Цвет",
                    },

                    /**
                     * Section for passing translations to the external tools classes
                     */
                    tools: {
                        /**
                         * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
                         * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
                         */
                        "warning": { // <-- 'Warning' tool will accept this dictionary section
                            "Title": "Название",
                            "Message": "Сообщение",
                        },
                        "header": {
                            "Heading 1": "Заголовок 1",
                            "Heading 2": "Заголовок 2",
                            "Heading 3": "Заголовок 3",
                            "Heading 4": "Заголовок 4",
                            "Heading 5": "Заголовок 5",
                            "Heading 6": "Заголовок 6",
                        },

                        /**
                         * Link is the internal Inline Tool
                         */
                        "link": {
                            "Add a link": "Вставьте ссылку"
                        },
                        /**
                         * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
                         */
                        "stub": {
                            'The block can not be displayed correctly.': 'Блок не может быть отображен'
                        },
                        "image": {
                            "Select an Image": "Выберите изображение",
                            "Caption": "Заголовок",
                            "With border": "Добавить границу",
                            "Stretch image": "Растянуть",
                            "With background": "Добавить фон",
                        },
                        "simpleImage": {
                            "Select an Image": "Выберите изображение",
                            "Caption": "Заголовок",
                            "Add Border": "Добавить границу",
                            "Stretch Image": "Растянуть",
                            "Add Background": "Добавить фон",
                        },
                        "gallery": {
                            'Select an Image': 'Выберите изображение',
                            'Delete': 'Удалить',
                            'Gallery caption': 'Подпись',
                            'Slider': 'Слайдер',
                            'Fit': 'Галерея',
                        },
                        "attaches": {
                            "Select file to upload": "Выберите файл",
                            "File title": "Заголовок",
                            "KiB": "КБ",
                        },
                        "list": {
                            "Unordered": "Маркированный",
                            "Ordered": "Нумерованный",
                        },
                        "quote": {
                            "Enter a quote": "Цитата",
                            "Enter a caption": "Автор",
                            "Align Left": "По левому краю",
                            "Align Center": "По центру",
                            "Align Right": "По правому краю",
                        },
                        "table": {
                            "With headings": "С заголовками",
                            "Without headings": "Без заголовков",
                            "Heading": "Заголовок",
                            "Add row above": "Добавить строку перед",
                            "Add row below": "Добавить строку после",
                            "Delete row": "Удалить строку",
                            "Add column to left": "Добавить колонку слева",
                            "Add column to right": "Добавить колонку справа",
                            "Delete column": "Удалить колонку",
                        },
                        "raw": {
                            "Enter HTML code": 'HTML-код'
                        },
                        "alert": {
                            "Primary": "Основной",
                            "Secondary": "Дополнительный",
                            "Info": "Информация",
                            "Success": "Успешно",
                            "Warning": "Предупрждение",
                            "Danger": "Опасность",
                            "Light": "Светлый",
                            "Dark": "Темный",
                        },
                        "embed": {
                            "Enter a caption": "Заголовок",
                        }
                    },

                    /**
                     * Section allows to translate Block Tunes
                     */
                    blockTunes: {
                        /**
                         * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
                         * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
                         *
                         * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
                         */
                        "delete": {
                            "Delete": "Удалить",
                            "Click to delete": "Подтвердить"
                        },
                        "moveUp": {
                            "Move up": "Переместить вверх"
                        },
                        "moveDown": {
                            "Move down": "Переместить вниз"
                        }
                    },
                }
            },
        });

        this.element.closest('form').querySelector('[type="submit"]').addEventListener('click', async function(event) {
            let data = await editor.save();
            this.element.value = JSON.stringify(data.blocks);

            return true;
        }.bind(this));
    }
}