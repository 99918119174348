import { Controller } from '@hotwired/stimulus';

import Routing from "../../../public/bundles/fosjsrouting/js/router";
const routes = require('../../../assets/js/fos_js_routes.json'); //file with generated routes, created after executing the command above.
Routing.setRoutingData(routes);

import Swal from "sweetalert2";

export default class extends Controller {
    static targets = [
        "rows",
        "selectedCount",
        "selectedButtons",
        "form",
        "formInputs",
    ];

    static values = {
        group: String,
        block: String,
        section: String,
    };

    toggleCheck() {
        let count = this.rowsTargets.map(function(value) {
            return value.querySelector('td:first-child input[type="checkbox"]').checked ? 1 : 0;
        }).reduce((carry, item) => carry + item, 0);

        if (count > 0) {
            this.selectedCountTarget.removeAttribute('hidden');
            this.selectedButtonsTargets.forEach(function(value) {
                value.classList.remove('disabled');
            });
        } else {
            this.selectedCountTarget.setAttribute('hidden', true);
            this.selectedButtonsTargets.forEach(function(value) {
                value.classList.add('disabled');
            });
        }

        this.selectedCountTarget.querySelector('span').innerText = count;
    }

    toggleCheckAll(event) {
        let checked = event.target.checked;

        this.rowsTargets.forEach(function(value) {
            value.querySelector('td:first-child input[type="checkbox"]').checked = checked;
        });

        if (checked) {
            this.selectedCountTarget.removeAttribute('hidden');
            this.selectedButtonsTargets.forEach(function(value) {
                value.classList.remove('disabled');
            });
        } else {
            this.selectedCountTarget.setAttribute('hidden', true);
            this.selectedButtonsTargets.forEach(function(value) {
                value.classList.add('disabled');
            });
        }

        this.selectedCountTarget.querySelector('span').innerText = this.rowsTargets.length;
    }

    connect() {
        this.rowsTargets.forEach(function(value) {
            value.onclick = function(event) {
                let tagName = event.target.tagName.toLowerCase();
                if (tagName == 'input' || tagName == 'a' || tagName == 'i' || tagName == 'button') {
                    return;
                }

                this.querySelector('td:first-child input[type="checkbox"]').click();
            }
        });
    }

    getItems(event) {
        if (event.target.closest('a').closest('tr') == null) {
            return this.rowsTargets.filter(function(value) {
                return value.querySelector('td:first-child input[type="checkbox"]').checked;
            }).reduce(function(carry, item) {
                carry.push((item.getAttribute('data-is-section') == '1' ? 'section' : 'element') +
                    '-' + item.getAttribute('data-value'));

                return carry;
            }, []);
        } else {
            return [(event.target.closest('a').closest('tr').getAttribute('data-is-section') == '1' ? 'section' : 'element') +
                '-' + event.target.closest('a').closest('tr').getAttribute('data-value')];
        }
    }

    getSectionsIds(items) {
        return items.filter(function(value) {
            let item = value.split('-');

            return item[ 0 ] === 'section';
        }).map(function(value) {
            let item = value.split('-');

            return item[ 1 ];
        });
    }

    getElementIds(items) {
        return items.filter(function(value) {
            let item = value.split('-');

            return item[ 0 ] === 'element';
        }).map(function(value) {
            let item = value.split('-');

            return item[ 1 ];
        });
    }

    moveFiles(event) {
        event.preventDefault();

        let items = this.getItems(event);
        let sectionsIds = this.getSectionsIds(items);
        let elementsIds = this.getElementIds(items);

        this.formInputsTarget.innerHTML = '';
        for (let i in sectionsIds) {
            this.formInputsTarget.innerHTML += '<input type="hidden" name="form[sections][]" value="' + sectionsIds[ i ] + '">';
        }
        for (let i in elementsIds) {
            this.formInputsTarget.innerHTML += '<input type="hidden" name="form[elements][]" value="' + elementsIds[ i ] + '">';
        }

        this.formTarget.setAttribute('action', Routing.generate('admin_block_elements_move', {
            id: this.groupValue,
            block_id: this.blockValue,
            section_id: this.sectionValue,
        }));

        this.formTarget.submit();
    }

    async deleteFiles(event) {
        event.preventDefault();

        let items = this.getItems(event);

        let result = await Swal.fire({
            title: 'Удаление',
            text: "Вы действительно хотите удалить " + (items.length > 1 ? "данные разделы и элементы" : "данный раздел или элемент") + "?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Удалить',
            cancelButtonText: 'Отменить'
        });

        if (!result.isConfirmed) {
            return;
        }

        if (this.getSectionsIds(items).length > 0) {
            let data = await $.post(Routing.generate('admin_block_sections_delete', {
                id: this.groupValue,
                block_id: this.blockValue,
            }), {
                sections: this.getSectionsIds(items),
            });

            if (data.status == 0) {
                alert(data.message);
                return;
            }
        }

        if (this.getElementIds(items).length > 0) {
            let data = await $.post(Routing.generate('admin_block_elements_delete', {
                id: this.groupValue,
                block_id: this.blockValue,
            }), {
                elements: this.getElementIds(items),
            });

            if (data.status == 0) {
                alert(data.message);
                return;
            }
        }

        window.location.reload();
    }
}