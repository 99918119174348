import { Controller } from '@hotwired/stimulus';

import Routing from "../../../public/bundles/fosjsrouting/js/router";
const routes = require('../../../assets/js/fos_js_routes.json'); //file with generated routes, created after executing the command above.
Routing.setRoutingData(routes);

import Swal from "sweetalert2";
import "select2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default class extends Controller {
    static targets = [
        'values'
    ];

    static values = {
        name: String,
    }

    addItem(event) {
        event.preventDefault();

        let newItem = document.createElement('div');
        newItem.classList.add('item');
        newItem.innerHTML = '<a href="#" class="btn btn-danger btn-icon" data-action="group--properties#deleteItem field--input#deleteItem">\n' +
                '<i class="fa fa-times"></i>\n' +
            '</a>' +
            this.element.getAttribute('data-prototype');
        this.valuesTarget.append(newItem);

        let items = this.valuesTarget.querySelectorAll('.item');

        items.forEach(function(item, index) {
            let elements = item.querySelectorAll('input, select, textarea');
            elements.forEach(function(element) {
                if (element.getAttribute('name') != null) {
                    element.setAttribute('name', element.getAttribute('name').replace('__name__', '0').replace(new RegExp('\\[([^\\]]+?)\\]$'), '[' + index + ']'));
                }

                if (element.getAttribute('id') != null) {
                    element.setAttribute('id', element.getAttribute('id').replace('__name__', '0').replace(new RegExp('([^_]+?)$'), index));
                }
            });
        });

        if (newItem.querySelector('textarea.editor') != null) {
            ClassicEditor
                .create( document.querySelector('#' + newItem.querySelector('textarea.editor').id) )
                .then( editor => {
                    window.editor = editor;
                } )
                .catch( error => {
                    console.error( 'There was a problem initializing the editor.', error );
                } );
        }
    }

    deleteItem(event) {
        event.preventDefault();

        event.target.closest('.item').remove();
    }
}