import { Controller } from '@hotwired/stimulus';

import Routing from "../../../public/bundles/fosjsrouting/js/router";
const routes = require('../../../assets/js/fos_js_routes.json'); //file with generated routes, created after executing the command above.
Routing.setRoutingData(routes);

import Swal from "sweetalert2";

export default class extends Controller {
    static targets = [
        "rows",
        "selectedCount",
        "selectedButtons",
        "form",
        "formInputs",
    ];

    static values = {
        template: String,
        path: String
    };

    toggleCheck() {
        let count = this.rowsTargets.map(function(value) {
            return value.querySelector('td:first-child input[type="checkbox"]').checked ? 1 : 0;
        }).reduce((carry, item) => carry + item, 0);

        if (count > 0) {
            this.selectedCountTarget.removeAttribute('hidden');
            this.selectedButtonsTargets.forEach(function(value) {
                value.classList.remove('disabled');
            });
        } else {
            this.selectedCountTarget.setAttribute('hidden', true);
            this.selectedButtonsTargets.forEach(function(value) {
                value.classList.add('disabled');
            });
        }

        this.selectedCountTarget.querySelector('span').innerText = count;
    }

    toggleCheckAll(event) {
        let checked = event.target.checked;

        this.rowsTargets.forEach(function(value) {
            value.querySelector('td:first-child input[type="checkbox"]').checked = checked;
        });

        if (checked) {
            this.selectedCountTarget.removeAttribute('hidden');
            this.selectedButtonsTargets.forEach(function(value) {
                value.classList.remove('disabled');
            });
        } else {
            this.selectedCountTarget.setAttribute('hidden', true);
            this.selectedButtonsTargets.forEach(function(value) {
                value.classList.add('disabled');
            });
        }

        this.selectedCountTarget.querySelector('span').innerText = this.rowsTargets.length;
    }

    connect() {
        this.rowsTargets.forEach(function(value) {
            value.onclick = function(event) {
                let tagName = event.target.tagName.toLowerCase();
                if (tagName == 'input' || tagName == 'a' || tagName == 'i' || tagName == 'button') {
                    return;
                }

                this.querySelector('td:first-child input[type="checkbox"]').click();
            }
        });
    }

    getFiles(event) {
        if (event.target.closest('a').closest('tr') == null) {
            return this.rowsTargets.filter(function(value) {
                return value.querySelector('td:first-child input[type="checkbox"]').checked;
            }).reduce(function(carry, item) {
                carry.push(item.getAttribute('data-value'));

                return carry;
            }, []);
        } else {
            return [event.target.closest('a').closest('tr').getAttribute('data-value')];
        }
    }

    moveFiles(event) {
        event.preventDefault();

        let files = this.getFiles(event);

        this.formInputsTarget.innerHTML = '';
        for (let i in files) {
            this.formInputsTarget.innerHTML += '<input type="hidden" name="form[files][]" value="' + files[ i ] + '">';
            this.formTarget.setAttribute('action', Routing.generate('admin_template_move_file', {
                id: this.templateValue,
                path: this.pathValue
            }));

            this.formTarget.submit();
        }
    }

    deleteFiles(event) {
        event.preventDefault();

        let files = this.getFiles(event);

        Swal.fire({
            title: 'Удаление',
            text: "Вы действительно хотите удалить " + (files.length > 1 ? "данные файлы" : "данный файл") + "?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Удалить',
            cancelButtonText: 'Отменить'
        }).then((result) => {
            if (result.isConfirmed) {
                $.post(Routing.generate('admin_template_delete_file', {
                    id: this.templateValue,
                    path: this.pathValue
                }), {
                    files: files
                })
                    .done(function(data) {
                        if (data.status == 1) {
                            window.location.reload();
                        } else {
                            alert(data.message);
                        }
                    }.bind(this));
            }
        });
    }
}