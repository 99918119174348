import { Controller } from '@hotwired/stimulus';

let $ = window.$ = require('jquery');

export default class extends Controller {
    static targets = [
        'type',
        'url',
        'page',
    ];

    connect() {
        super.connect();

        $(this.typeTarget).on('change', function(e) {
            this.changeType(e);
        }.bind(this));

        this.changeType();
    }

    changeType(e) {
        let typeUrl = 0;
        let typePage = 1;

        if (this.typeTarget.value == typeUrl) {
            this.urlTarget.closest('.row').removeAttribute('hidden');
        } else {
            this.urlTarget.closest('.row').setAttribute('hidden', true);
        }

        if (this.typeTarget.value == typePage) {
            this.pageTarget.closest('.row').removeAttribute('hidden');
        } else {
            this.pageTarget.closest('.row').setAttribute('hidden', true);
        }
    }
}