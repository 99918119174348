import {Controller} from '@hotwired/stimulus';

import { Datepicker } from 'vanillajs-datepicker';
import ru from 'vanillajs-datepicker/locales/ru';

export default class extends Controller {


    connect() {
        super.connect();

        Object.assign(Datepicker.locales, ru);

        this.datepicker = new Datepicker(this.element, {
            buttonClass: 'btn',
            autohide: true,
            clearButton: true,
            language: 'ru',
            format: 'dd.mm.yyyy',
        });
    }

    disconnect() {
        super.disconnect();

        this.datepicker.destroy();
    }
}