import { Controller } from '@hotwired/stimulus';

let $ = window.$ = require('jquery');

export default class extends Controller {
    connect() {
        $(this.element.querySelector('[name$="[type]"]')).on('change', function() {
            this.changeType();
        }.bind(this));

        this.changeType();
    }

    changeType() {
        let valuesBlock = this.element.querySelector('[data-controller="field--input"]').closest('.row');

        if (this.element.querySelector('[name$="[type]"]').value == 9) {
            valuesBlock.removeAttribute('hidden');
        } else {
            valuesBlock.setAttribute('hidden', true);
        }
    }
}