import { Controller } from '@hotwired/stimulus';

import Routing from "../../../public/bundles/fosjsrouting/js/router";
const routes = require('../../../assets/js/fos_js_routes.json'); //file with generated routes, created after executing the command above.
Routing.setRoutingData(routes);

import Swal from "sweetalert2";

export default class extends Controller {
    static values = {
        code: String,
        template: String,
    }

    connect() {
        super.connect();

        this.element.querySelector('form').addEventListener('submit', async function(e) {
            e.preventDefault();

            if (this.element.getAttribute('data-loading') != null) {
                return;
            }

            this.element.setAttribute('data-loading', '1');

            this.element.querySelector('input[name$="[refererUrl]"]').value = window.location.href;
            this.element.querySelector('input[name$="[refererTitle]"]').value = document.title;
            this.element.querySelector('input[name$="[emailFormTemplate]"]').value = this.templateValue;

            let formData = new FormData();

            this.element.querySelectorAll('input, textarea, select').forEach(function(element) {
                if (element.name == undefined || element.name == '') {
                    return;
                }

                formData.append(element.name, element.value);
            });

            let response = await fetch(Routing.generate('public_requests_new', {
                code: this.codeValue
            }), {
                method: "POST",
                body: formData
            });

            let result = await response.json();

            this.element.removeAttribute('data-loading');

            if (this.element.querySelector('form .captcha_reload') != null) {
                this.element.querySelector('form .captcha_reload').click();
                this.element.querySelector('form input[name$="[captcha]"]').value = '';
            }

            if (result.status == 0) {
                Swal.fire({
                    title: 'Ошибка',
                    text: result.message,
                    icon: 'error',
                });

                return;
            }

            Swal.fire({
                title: 'Спасибо!',
                text: 'Ваша заявка отправлена.',
                icon: 'success',
            });

            let modalId = this.element.closest('.modal').getAttribute('id');
            let button = document.querySelector('[data-bs-target="#' + modalId + '"]');
            if (button != null && button.closest('[data-controller="block--basket"]') != null) {
                localStorage.removeItem('basket');

                setTimeout(function() {
                    window.location.reload();
                }, 3000);
            }

            if (this.element.closest('.modal') != null) {
                this.element.closest('.modal').querySelector('[data-bs-dismiss]').click();
            }
        }.bind(this));

        if (this.element.closest('.modal') != null) {
            this.element.closest('.modal').querySelector('.modal-footer .btn-primary').addEventListener('click', function(e) {
                e.preventDefault();

                this.element.querySelector('form button[type="submit"]').click();
                //this.element.querySelector('form').dispatchEvent(new Event('submit'));
            }.bind(this));
        }

        let modal = this.element.closest('.modal');
        if (modal != null && !modal.parentElement.matches('body')) {
            modal.querySelector('[type="submit"]').setAttribute('hidden', true);
            modal.querySelector('.separator').setAttribute('hidden', true);

            document.querySelector('body').append(modal);
        }
    }
}