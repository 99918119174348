import {Controller} from '@hotwired/stimulus';

import Inputmask from "inputmask";

export default class extends Controller {
    connect() {
        super.connect();

        if (this.element.value == '') {
            this.element.value = '+7';
        }

        Inputmask({
            "mask": "+9 (999) 999-9999"
        }).mask(this.element);
    }
}