import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        origin: String,
    }

    translit(str) {

        let ru = {
            'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
            'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i',
            'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
            'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
            'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
            'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya'
        };
        let allowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].concat('abcdefghijklmnopqrstuvwxyz'.split(''));
        let result = [];

        str = str.replace(/[ъь]+/g, '').replace(/й/g, 'i');

        for (var i = 0; i < str.length; ++i) {
            let symbol = str[i].toLowerCase();

            if (allowed.includes(symbol)) {
                result.push(symbol);
                continue;
            }

            if (ru[ symbol ] !== undefined) {
                result.push(ru[ symbol ]);
                continue;
            }

            if (result.length > 0 && result[ result.length - 1 ] === '-') {
                continue;
            }

            result.push('-');
        }

        return result.join('');
    }

    connect() {
        super.connect();

        let originElement = this.element.closest('form').querySelector('[name$="[' + this.originValue + ']"]');

        if (this.element.value === this.translit(originElement.value)) {
            this.element.setAttribute('data-changed', '0');
        }

        originElement.addEventListener('change', function() {
            if (this.element.getAttribute('data-changed') == '1') {
                return;
            }

            this.element.value = this.translit(originElement.value);
        }.bind(this));
        originElement.addEventListener('keyup', function() {
            originElement.dispatchEvent(new Event('change'));
        }.bind(this));

        this.element.addEventListener('change', function() {
            if (this.element.value === this.translit(originElement.value)) {
                this.element.setAttribute('data-changed', '0');
            } else {
                this.element.setAttribute('data-changed', '1');
            }
        }.bind(this));
        this.element.addEventListener('keyup', function() {
            this.element.dispatchEvent(new Event('change'));
        }.bind(this));
    }
}