import { Controller } from '@hotwired/stimulus';

import Routing from "../../../public/bundles/fosjsrouting/js/router";
const routes = require('../../../assets/js/fos_js_routes.json'); //file with generated routes, created after executing the command above.
Routing.setRoutingData(routes);

import "select2";

export default class extends Controller {
    connect() {
        $(this.element).select2({
            ajax: {
                url: Routing.generate('admin_lists_query', {list: 'geolocation_city'}),
                dataType: 'json',
                processResults: function (data) {
                    return {
                        results: data.results
                    };
                }
            }
        });
    }
}