import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'button',
        'icon',
        'menu'
    ];

    connect() {
        this.buttonTarget.addEventListener('click', function(e) {
            this.toggle(e);
        }.bind(this));
    }

    toggle(e) {
        if (!this.hasIconTarget) {
            return;
        }

        if (this.menuTarget.hasAttribute('hidden')) {
            this.menuTarget.removeAttribute('hidden');
            this.iconTarget.class = 'bi bi-caret-up-fill';
        } else {
            this.menuTarget.setAttribute('hidden', true);
            this.iconTarget.class = 'bi bi-caret-down-fill';
        }
    }
}