import { Controller } from '@hotwired/stimulus';

import Routing from "../../../public/bundles/fosjsrouting/js/router";
const routes = require('../../../assets/js/fos_js_routes.json'); //file with generated routes, created after executing the command above.
Routing.setRoutingData(routes);

import Swal from "sweetalert2";

export default class extends Controller {
    static targets = [
        'addToCart',
        'moveToCart',
        'count',
    ];

    static values = {
        group: String,
        elementId: Number,
    };

    connect() {
        this.display();
    }

    getBasketId() {
        return 'basket_' + this.groupValue;
    }

    decCount(e) {
        e.preventDefault();

        this.setValue(this.getValue() - 1);
        this.display();
    }

    incCount(e) {
        e.preventDefault();

        this.setValue(this.getValue() + 1);
        this.display();
    }

    getValue() {
        let basket = localStorage.getItem(this.getBasketId()) != null ?
            JSON.parse(localStorage.getItem(this.getBasketId())) : [];

        let count = 0;
        basket.forEach(element => {
            if (element.id == this.elementIdValue) {
                count = element.count;
                return false;
            }
        });

        return count;
    }

    setValue(count) {
        let basket = localStorage.getItem(this.getBasketId()) != null ?
            JSON.parse(localStorage.getItem(this.getBasketId())) : [];

        if (count > 0) {
            let find = false;
            for (let i in basket) {
                if (basket[ i ].id != this.elementIdValue) {
                    continue;
                }

                basket[ i ].count = count;
                find = true;
            }

            if (!find) {
                basket.push({
                    id: this.elementIdValue,
                    count: count,
                });
            }
        }

        if (count == 0) {
            basket = basket.filter(function(element) {
                return element.id != this.elementIdValue;
            }.bind(this));
        }

        if (basket.length == 0) {
            localStorage.removeItem(this.getBasketId());
            return;
        }

        localStorage.setItem(this.getBasketId(), JSON.stringify(basket));
    }

    display() {
        let count = this.getValue();

        if (count == 0) {
            this.addToCartTarget.removeAttribute('hidden');
            this.moveToCartTarget.setAttribute('hidden', true);
            return;
        }

        this.addToCartTarget.setAttribute('hidden', true);
        this.moveToCartTarget.removeAttribute('hidden');

        this.countTarget.innerHTML = count;
    }
}